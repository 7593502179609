import React from "react";

import Layout from "../../components/layout/Layout";
import SEO from "../../components/seo/SEO";
import VmDetail from "../../components/vm-detail/VmDetail";

export const VmDetailPage = (props: any) => {
  return (
    <Layout>
      <SEO title={"vm-detail"} />
      <VmDetail vmname={props?.location?.state?.vmname} />
    </Layout>
  );
};

export default VmDetailPage;
