import React, { FunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { Box, Button, Typography } from "@material-ui/core";
import IFrameComponent from "../i-frame-component";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// @ts-ignore
export interface VmDetailProps {
  vmname: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainGrid: {
      padding: theme.spacing(1, 2, 0, 2),
    },
    text: {
      padding: theme.spacing(0, 0, 2),
    },
  })
);

const baseUrl = "https://unified-cloud.ignw.io";

const text = {
  title: "VM Details: ",
  powerState: "",
  powerStateLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&refresh=5s&var-vm={{vmname}}&panelId=16`,
  cpuCount: "Number of CPUs",
  cpuCountLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&refresh=5s&var-vm={{vmname}}&panelId=18`,
  memoryCapacity: "Memory Capacity (GiB)",
  memoryCapacityLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&refresh=5s&var-vm={{vmname}}&panelId=20`,
  storageCapacity: "Storage Capacity (GiB)",
  storageCapacityLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&var-vm={{vmname}}&panelId=22`,
  cpuUsage: "CPU Usage",
  cpuUsageLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&var-vm={{vmname}}&panelId=2`,
  cpuUsageGraph: "CPU Usage (mhz) Over Time",
  cpuUsageGraphLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&var-vm={{vmname}}&panelId=8`,
  memoryUsage: "Memory Usage",
  memoryUsageLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&var-vm={{vmname}}&panelId=4`,
  memoryUsageGraph: "Memory Usage (mhz) Over Time",
  memoryUsageGraphLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&var-vm={{vmname}}&panelId=9`,
  diskUsage: "Disk Usage",
  diskUsageLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&var-vm={{vmname}}&panelId=6`,
  diskUsageGraph: "Disk Usage (mhz) Over Time",
  diskUsageGraphLink: `${baseUrl}/monitoring/d-solo/1xkIvjmGz/virtual-machine?orgId=1&var-vm={{vmname}}&panelId=10`,
};

const HEIGHT = "350px";
const HEIGHTSM = "150px";

const WIDTH = "180px";

export const VmDetail: FunctionComponent<VmDetailProps> = (props) => {
  const classes = useStyles();
  const { vmname } = props;

  return (
    <Box flex={1} className={classes.mainGrid}>
      <Grid
        container
        direction={"row"}
        className={classes.text}
        justify="space-between"
      >
        <Grid item>
          <Typography variant={"h5"}>
            {text.title} {vmname}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            href="/compute/virtualization"
            style={{ minWidth: 250 }}
          >
            Workloads
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHTSM}
            title={text.powerState}
            src={text.powerStateLink}
            vmname={vmname}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHTSM}
            title={text.cpuCount}
            src={text.cpuCountLink}
            vmname={vmname}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHTSM}
            title={text.memoryCapacity}
            src={text.memoryCapacityLink}
            vmname={vmname}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHTSM}
            title={text.storageCapacity}
            src={text.storageCapacityLink}
            vmname={vmname}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.cpuUsage}
            src={text.cpuUsageLink}
            vmname={vmname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.cpuUsageGraph}
            src={text.cpuUsageGraphLink}
            vmname={vmname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.memoryUsage}
            src={text.memoryUsageLink}
            vmname={vmname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.memoryUsageGraph}
            src={text.memoryUsageGraphLink}
            vmname={vmname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.diskUsage}
            src={text.diskUsageLink}
            vmname={vmname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.diskUsageGraph}
            src={text.diskUsageGraphLink}
            vmname={vmname}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VmDetail;
